const rsvpForm = document.querySelector('[data-rsvp-form]');
const rsvpRadios = document.getElementsByName('rsvpd');
const acceptOnlyItems = document.querySelectorAll('[data-accept-only]');
const guestsInput = document.getElementById('guests');
const dinnerInputs = document.querySelectorAll('[data-rsvp-dinner-field]');
const dietInputs = document.querySelectorAll('[data-rsvp-diet-field]');
const guestBox = document.querySelector('[data-guest-index]');
const meals = document.querySelector('[data-meal-container]');

// show/hide fields based on accepted or declined status
function hideAcceptOnlyFields() {
  guestsInput.setAttribute('disabled', '');

  for (let i = 0; i < dinnerInputs.length; i++) {
    dinnerInputs[i].setAttribute('disabled', '');
  }

  for (let i = 0; i < dietInputs.length; i++) {
    dietInputs[i].setAttribute('disabled', '');
  }

  for (let i = 0; i < acceptOnlyItems.length; i++) {
    acceptOnlyItems[i].classList.add('hidden');
  }
}

function showAcceptOnlyFields() {
  guestsInput.removeAttribute("disabled");

  for (let i = 0; i < dinnerInputs.length; i++) {
    dinnerInputs[i].removeAttribute('disabled');
  }

  for (let i = 0; i < dietInputs.length; i++) {
    dietInputs[i].removeAttribute('disabled');
  }

  for (let i = 0; i < acceptOnlyItems.length; i++) {
    acceptOnlyItems[i].classList.remove('hidden');
  }
}

function checkRSVP() {
  // check accepted or declined status
  for (let i = 0; i < rsvpRadios.length; i++) {
    if (rsvpRadios[i].checked && rsvpRadios[i].value == "declined") {
      // if user chose declined, disable and hide irrelevant inputs
      hideAcceptOnlyFields();
    } else if (rsvpRadios[i].checked && rsvpRadios[i].value == "accepted") {
      // if user chose accepted, enable and show irrelevant inputs
      showAcceptOnlyFields();
      countGuests();
    }
  }
}

function countGuests() {
  let guestCount = guestsInput.value;
  let guestBoxes = document.querySelectorAll('[data-guest-index]');
  let guestBoxCount = guestBoxes.length;
  let diff = guestBoxCount - guestCount;
  let newGuestBox;

  function addGuestBoxes() {
    while (diff < 0) {
      guestBoxCount++;

      newGuestBox = `
      <li class="rsvp__meal rsvp__guest-${guestBoxCount}" data-guest-index>
        <h3>Guest ${guestBoxCount}'s meal selection</h3>
        <label for="guest-name-${guestBoxCount}">Guest ${guestBoxCount} Name <input type="text" name="guest-names-[]" id="guest-name-${guestBoxCount}" placeholder="Answald Martin" required/></label>
        <ul class="list-reset">
            <li>
              <label for="dinner-${guestBoxCount}">Dinner Selection
                <select name="dinner-choices-[]" id="dinner-${guestBoxCount}" data-rsvp-dinner-field>
                  <option value="short-rib">Short Rib</option>
                  <option value="sea-bass">Sea Bass</option>
                  <option value="roasted-eggplant">Vegan Roasted Eggplant</option>
                </select>
              </label>
          </li>
          <li class="rsvp__diet rsvp__guest-${guestBoxCount}">
            <label for="diet-${guestBoxCount}">Dietary restrictions or allergies
              <textarea name="diet-restrictions-[]" id="diet-${guestBoxCount}" placeholder="Vegan, nut allergy" data-rsvp-diet-field></textarea>
            </label>
          </li>
        </ul>
      </li>
    `;
      meals.insertAdjacentHTML('beforeend', newGuestBox);
      diff++;
    }
  }

  function removeGuestBoxes() {
    while (diff > 0) {
      let last = guestBoxes[guestBoxes.length - diff];
      last.remove();
      diff--;
    }
  }

  if (guestBoxCount < guestCount) {
    addGuestBoxes(guestCount);
  } else if (guestBoxCount > guestCount) {
    removeGuestBoxes(guestBoxCount,guestCount,guestBoxes);
  }
}

// listeners
rsvpForm.addEventListener('change', checkRSVP);
guestsInput.addEventListener('change', countGuests);
