// https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/#using-javascript-local-storage
const btn = document.querySelector(".btn-dark-toggle");
let prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
let theme = localStorage.getItem("theme");

btn.addEventListener("click", function () {
  changeTheme();
});

function changeTheme() {
  if (prefersDarkScheme.matches) {
    document.body.classList.toggle("light-mode");
    document.body.classList.remove("dark-mode");
    theme = document.body.classList.contains("light-mode") ? "light" : "dark";
    localStorage.setItem("theme", theme);
  } else {
    document.body.classList.toggle("dark-mode");
    document.body.classList.remove("light-mode");
    theme = document.body.classList.contains("dark-mode") ? "dark" : "light";
    localStorage.setItem("theme", theme);
  }
}
