import Glide from '@glidejs/glide'

new Glide('.glide', {
  animationDuration: 800,
  animationTimingFunc: 'ease-in-out',
  autoplay: 4120,
  bound: true,
  gap: 4,
  peek: { before: 8, after: 8 },
  type: 'carousel',
  perView: 2,
  breakpoints: {
    540: {
      peek: { before: 0, after: 0 },
      perView: 1
    }
  },
}).mount();
