let toggle = document.querySelector('.js-btn-nav-toggle');
let menu = document.querySelector('.js-menu');
let menuItems = document.querySelectorAll('.js-menu li a');

toggle.addEventListener('click', function () {
  if (menu.classList.contains('is-active')) {
    this.setAttribute('aria-expanded', 'false');
    menu.classList.remove('is-active');
  } else {
    menu.classList.add('is-active');
    this.setAttribute('aria-expanded', 'true');
    //menuItems[0].focus();
  }
});
