const detectedElement = document.querySelector('[data-detect-edge]');
const fadeOverlay = detectedElement.querySelector('[data-fade-overlay]');

detectedElement.addEventListener('scroll', () => {
  if (detectedElement.offsetWidth + detectedElement.scrollLeft >= detectedElement.scrollWidth) {
    fadeOverlay.classList.add('rotate-180');
  } else {
    fadeOverlay.classList.remove('rotate-180');
  }
});
